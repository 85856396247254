import React from "react";

import { Icon } from '@types';

const GitHubIcon: Icon = ({ fill = "white", ...props }) => (
  <svg
    width="16"
    height="13"
    viewBox="0 0 360 350"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M300.208 321.323C368.182 251.004 385.835 169.752 353.165 77.5668C316.542 -5.77541 235.157 -21.7674 109.011 29.5909C-17.1362 80.9492 -33.8004 159.991 59.0179 266.715C151.836 373.44 232.233 391.643 300.208 321.323ZM254.326 250.776C301.602 201.836 313.843 145.252 291.051 81.0255C265.625 23.1218 208.995 11.979 121.16 47.5971C33.3243 83.2153 21.7409 138.218 86.4092 212.606C151.077 286.993 207.05 299.717 254.326 250.776Z"
      fill= {fill}
    ></path>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M215.161 190.247C243.747 160.54 251.029 126.076 237.006 86.8572C221.516 51.6543 187.111 44.7477 133.791 66.1375C80.4717 87.5273 73.5569 120.987 113.047 166.515C152.537 212.044 186.575 219.955 215.161 190.247ZM185.438 144.124C198.676 130.221 201.896 113.946 195.098 95.2998C187.634 78.5463 171.46 75.082 146.576 84.9069C121.692 94.7319 118.639 110.533 137.416 132.311C156.193 154.089 172.2 158.026 185.438 144.124Z"
      fill= {fill}
    ></path>
  </svg>
);

export default GitHubIcon;
